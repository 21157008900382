// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-acerca-de-nosotros-js": () => import("./../../../src/pages/acerca-de-nosotros.js" /* webpackChunkName: "component---src-pages-acerca-de-nosotros-js" */),
  "component---src-pages-calentadores-solares-js": () => import("./../../../src/pages/Calentadores-solares.js" /* webpackChunkName: "component---src-pages-calentadores-solares-js" */),
  "component---src-pages-colectores-para-alberca-js": () => import("./../../../src/pages/colectores-para-alberca.js" /* webpackChunkName: "component---src-pages-colectores-para-alberca-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-paneles-solares-js": () => import("./../../../src/pages/Paneles-solares.js" /* webpackChunkName: "component---src-pages-paneles-solares-js" */)
}

